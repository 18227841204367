<template>
  <div>
    <h3>{{ $t("devices.device") }}</h3>
    <v-divider />

    <div class="d-flex justify-space-between align-center">
      <v-autocomplete
        v-model="localValue"
        :items="devices"
        item-text="identifier"
        return-object
        :label="$t('devices.selectDevice')"
        prepend-inner-icon="mdi-video-input-hdmi"
        class="mr-4"
        v-if="isNothing"
      />

      <div v-else>
        <v-chip
          color="primary"
          close
          class="ma-2"
          @click:close="localValue = null"
          >{{ localValue.identifier }}</v-chip
        >
      </div>

      <!-- Add new device button (If no device has been added) -->
      <v-dialog v-model="dialog" v-if="isNothing" max-width="500">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" text>
            <v-icon>mdi-plus</v-icon> {{ $t("devices.newDevice") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title>{{ $t("devices.createTitle") }}</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="createDevice.identifier"
              :label="$t('devices.fields.identifier')"
              prepend-inner-icon="mdi-video-input-hdmi"
              :hint="$t('devices.hints.identifier')"
              presistent-hint
            />
          </v-card-text>

          <v-card-text>
            <v-text-field
              v-model="createDevice.resourceKey"
              :label="$t('devices.fields.resourceKey')"
              prepend-inner-icon="mdi-key"
              :hint="$t('devices.hints.resourceKey')"
              presistent-hint
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              {{ $t("common.cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="save()">
              {{ $t("common.add") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },

  model: { prop: "modelValue" },

  data() {
    return {
      dialog: false,
      createDevice: {
        identifier: "",
        resourceKey: "",
      },
    };
  },

  computed: {
    ...mapState("devices", ["devices"]),

    localValue: {
      get() {
        return this.modelValue;
      },

      set(v) {
        this.$emit("input", v);
      },
    },

    isNothing() {
      return this.localValue == null || this.localValue.identifier == "";
    },
  },

  methods: {
    ...mapActions("devices", ["getAvaliableDevices", "addDevice"]),

    save() {
      if (
        this.createDevice.identifier != "" &&
        this.createDevice.identifier != null
      ) {
        this.addDevice(this.createDevice);
        this.localValue = this.createDevice;
        this.dialog = false;
      }
    },

    async fetchDevices() {
      await this.getAvaliableDevices();
    },

    setFirst() {
      this.localValue = this.devices[0];
    },
  },

  async created() {
    await this.getAvaliableDevices();
  },
};
</script>