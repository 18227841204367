<template>
  <v-container fluid>
    <v-switch
      v-model="keepValues"
      label="Keep values after form submit"
      hint="Can be useful when adding multiple"
      v-if="permitted('Silo.KeepValuesOfForm')"
    />
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          v-model="name"
          prepend-inner-icon="mdi-form-textbox"
          :label="$t('silos.fields.name')"
        />
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          v-model="comment"
          prepend-inner-icon="mdi-comment"
          :label="$t('silos.fields.comment')"
        />
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6">
        <content-selector v-model="siloContentId" />
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6">
        <supplier-selector v-model="suppliers" />
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          v-model="location"
          prepend-inner-icon="mdi-crosshairs-gps"
          :label="$t('silos.fields.location')"
        />
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="6">
        <v-switch
          v-model="active"
          :label="$t('silos.fields.active')"
        ></v-switch>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <location-picker
          :lat="lat"
          :lng="lng"
          v-on:update-lat="(v) => (lat = v)"
          v-on:update-lng="(v) => (lng = v)"
        />
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <device-selector v-model="device" ref="deviceSelector" />
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <silo-property-selector v-model="siloPropertyId" />
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <error-list :errors="errors" />
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <v-spacer></v-spacer>
        <v-btn @click="save" color="primary">{{ $t("common.save") }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LocationPicker from "../maps/LocationPicker.vue";
import DeviceSelector from "../devices/DeviceSelector.vue";
import SiloPropertySelector from "./SiloPropertySelector.vue";
import ContentSelector from "../contents/ContentSelector.vue";
import SupplierSelector from "./SupplierSelector.vue";
import { mapActions } from "vuex";

export default {
  name: "SiloForm",

  components: {
    LocationPicker,
    DeviceSelector,
    SiloPropertySelector,
    ContentSelector,
    SupplierSelector,
  },

  props: {
    silo: {
      type: Object || null,
      default: null,
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: "",
      siloContentId: null,
      location: "",
      lat: null,
      lng: null,
      active: true,
      siloPropertyId: null,
      keepValues: false,
      comment: "",
      device: {
        identifier: "",
        resrouceKey: null,
      },
      suppliers: [],

      errors: [],
    };
  },

  methods: {
    ...mapActions("silos", ["createSilo", "updateSilo", "getSilos"]),

    async save() {
      this.errors = [];
      let err = [];
      if (this.name == "" || this.name == null)
        err.push(this.$t("silos.errors.name"));
      if (this.location == "" || this.location == null)
        err.push(this.$t("silos.errors.location"));
      if (
        this.lat == "" ||
        this.lat == null ||
        this.lng == "" ||
        this.lng == null
      )
        err.push(this.$t("silos.errors.latLng"));
      if (this.siloPropertyId == "" || this.siloPropertyId == null)
        err.push(this.$t("silos.errors.siloPropertyId"));
      if (
        this.device == null ||
        this.device.identifier == undefined ||
        this.device.identifier == ""
      )
        err.push(this.$t("silos.errors.device"));

      if (this.siloContentId == null)
        err.push(this.$t("silos.errors.siloContentId"));

      if (err.length > 0) {
        this.errors = err;
        return;
      }

      let payload = {
        name: this.name,
        location: this.location,
        lat: this.lat,
        lng: this.lng,
        active: this.active,
        siloPropertyId: this.siloPropertyId,
        device: this.device,
        siloContentId: this.siloContentId,
        comment: this.comment,
        suppliers: this.suppliers,
      };

      let res = false;
      if (!this.edit) res = await this.createSilo(payload);
      else
        res = await this.updateSilo({
          siloId: this.silo.siloId,
          payload: payload,
        });

      if (res) {
        if (!this.keepValues) {
          this.name = "";
          this.siloContentId = null;
          this.location = "";
          this.lat = null;
          this.lng = null;
          this.siloPropertyId = null;
        }
        this.comment = "";
        this.active = true;
        this.device = { identifier: "", resrouceKey: null };

        if (this.keepValues) {
          await this.$refs.deviceSelector.fetchDevices();
          this.$refs.deviceSelector.setFirst();
        }

        if (!this.keepValues) this.$router.push("/silos");
      }
    },
  },

  async created() {
    await this.getSilos();
    if (this.silo != null) {
      this.name = this.silo.name;
      this.contents = this.silo.contents;
      this.location = this.silo.location;
      this.lat = this.silo.lat;
      this.lng = this.silo.lng;
      this.siloPropertyId = this.silo.siloPropertyId;
      this.active = this.silo.active;
      this.device = this.silo.device;
      this.siloContentId = this.silo.siloContentId;
      this.comment = this.silo.comment;
      this.suppliers = this.silo.supplierIds;
    }
  },
};
</script>