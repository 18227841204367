<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <v-autocomplete
        v-model="localValue"
        :items="contents"
        item-text="name"
        item-value="siloContentId"
        :label="$t('contents.select')"
        prepend-inner-icon="mdi-counter"
        class="mr-4"
      />

      <v-dialog v-model="dialog" max-width="500">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" text>
            <v-icon>mdi-plus</v-icon> {{ $t("contents.newContent") }}
          </v-btn>
        </template>

        <content-form
          v-on:close-dialog="dialog = false"
          :hideDialogButton="false"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ContentForm from "./ContentForm.vue";

export default {
  components: { ContentForm },

  props: {
    modelValue: {
      type: String || null,
      default: null,
    },
  },

  model: { prop: "modelValue" },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapState("contents", ["contents"]),

    localValue: {
      get() {
        return this.modelValue;
      },

      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    ...mapActions("contents", ["getSiloContents"]),
  },

  async created() {
    await this.getSiloContents();
  },
};
</script>