<template>
  <div>
    <h3>{{ $t("silos.siloProperty.title") }}</h3>
    <v-divider />

    <div class="d-flex justify-space-between align-center">
      <v-text-field
        v-model="search"
        :label="$t('common.search')"
        prepend-inner-icon="mdi-magnify"
      />

      <v-dialog v-model="dialog" max-width="500">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" text>
            <v-icon>mdi-plus</v-icon>
            {{ $t("silos.siloProperty.newSiloTitle") }}
          </v-btn>
        </template>

        <silo-property-form v-on:close-dialog="dialog = false" />
      </v-dialog>
    </div>

    <div style="width: 100%">
      <silo-property-carousel v-model="localValue" :search="search" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SiloPropertyCarousel from "./SiloPropertyCarousel.vue";
import SiloPropertyForm from "./SiloPropertyForm.vue";

export default {
  components: { SiloPropertyCarousel, SiloPropertyForm },

  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },

  model: { prop: "modelValue" },

  data() {
    return {
      search: "",
      dialog: false,
      createDevice: {
        identifier: "",
        resourceKey: "",
      },
    };
  },

  computed: {
    ...mapState("devices", ["devices"]),

    localValue: {
      get() {
        return this.modelValue;
      },

      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    async save() {
      if (
        this.createDevice.identifier != "" &&
        this.createDevice.identifier != null
      ) {
        await this.addSiloProperty(this.createDevice);
        this.localValue = this.createDevice;
        this.dialog = false;
      }
    },
  },
};
</script>