<template>
  <div>
    <gmap-autocomplete
      class="search-address-bar"
      @place_changed="placeChanged"
    />
    <gmap-map
      :center="pos"
      :zoom="7"
      map-type-id="terrain"
      style="width: 100%; height: 500px; max-width: 100%"
      @click="setNewLocation"
    >
      <gmap-marker
        v-if="marker != null"
        :key="marker.lat"
        :position="marker"
        :icon="{
          url: 'icons/silo-marker.png',
          scaledSize: { height: 40, width: 40 },
        }"
      />
    </gmap-map>
  </div>
</template>

<script>
import { getGoogleMapsAPI } from "gmap-vue";

export default {
  props: {
    lat: {
      type: Number,
      default: null,
    },
    lng: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      pos: {
        lat: 59.27412,
        lng: 15.2066,
      },

      markers: [],
    };
  },

  computed: {
    google: getGoogleMapsAPI,
  },

  methods: {
    placeChanged(val) {
      if (val != null) {
        this.marker.lat = val.geometry.location.lat();
        this.marker.lng = val.geometry.location.lng();

        this.$emit("update-lat", this.marker.lat);
        this.$emit("update-lng", this.marker.lng);
      }
    },

    setNewLocation(e) {
      if (e.latLng.lat() != null && e.latLng.lng() != null) {
        this.marker.lat = e.latLng.lat();
        this.marker.lng = e.latLng.lng();

        this.$emit("update-lat", this.marker.lat);
        this.$emit("update-lng", this.marker.lng);
      }
    },
  },

  async created() {
    // Try to get current users location
    if (this.lat == null || this.lng == null) {
      const success = (pos) => {
        this.pos.lat = pos.coords.latitude;
        this.pos.lng = pos.coords.longitude;
      };

      navigator.geolocation.getCurrentPosition(success);

      this.$emit("update-lat", this.pos.lat);
      this.$emit("update-lng", this.pos.lng);
    } else {
      this.pos = {
        lat: this.lat,
        lng: this.lng,
      };
    }

    this.marker = this.pos;
  },

  watch: {
    lat(val) {
      if (val != null) this.marker.lat = val;
    },

    lng(val) {
      if (val != null) this.marker.lng = val;
    },
  },
};
</script>

<style lang="scss">
.search-address-bar {
  width: 100%;
  padding: 0.3rem;
  border-bottom: 1px solid;

  input {
    width: 100%;
    border-bottom: 1px solid;
    padding: 0.2rem;
  }
}
</style>