<template>
  <v-autocomplete
    v-model="localSuppliers"
    :items="suppliers"
    item-text="name"
    item-value="companyId"
    prepend-inner-icon="mdi-account"
    :label="$t('silos.fields.suppliers')"
    multiple
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SupplierSelector",

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "modelValue",
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("companies", ["suppliers"]),

    localSuppliers: {
      get() {
        return this.modelValue;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("companies", ["getSuppliers"]),
  },

  async created() {
    await this.getSuppliers();
  },
};
</script>