<template>
  <div class="silo-carousel d-inline-flex">
    <div
      class="silo-carousel-item ma-3"
      :key="m.siloPropertyId"
      v-for="m in filteredProperties"
    >
      <silo-property-from :modelValue="m" :display="true" :type="m.type" />
      <h4 class="text-center">{{ m.name }}</h4>
      <div class="d-flex justify-center align-center pb-4">
        <v-radio-group v-model="localValue">
          <v-radio :value="m.siloPropertyId" />
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SiloPropertyFrom from "./SiloPropertyItem.vue";

export default {
  components: { SiloPropertyFrom },

  props: {
    search: {
      type: String,
      default: "",
    },

    modelValue: {
      type: String,
      default: null,
    },
  },

  model: {
    prop: "modelValue",
  },

  computed: {
    ...mapState("silos", ["properties"]),

    localValue: {
      get() {
        return this.modelValue;
      },

      set(val) {
        this.$emit("input", val);
      },
    },

    filteredProperties() {
      if (this.search == null || this.search == "") return this.properties;

      return this.properties.filter(
        (d) =>
          d.name && d.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },

  methods: {
    ...mapActions("silos", ["getSiloProperties"]),
  },

  async created() {
    await this.getSiloProperties();
  },
};
</script>

<style lang="scss">
.silo-carousel {
  overflow-x: scroll;
  width: 100% !important;

  .silo-carousel-item {
    border: 1px solid black;
    border-radius: 5px;
  }
}
</style>